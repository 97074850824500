import {
    CompaniesGetRequest,
    CompanyJSON,
    CompanySubJSON,
    EnhancedCompany,
    EnhancedCompanyForm,
    EnhancedCompanySub,
    EnhancedCompanySubForm,
    EnhancedEntityOptions,
    EnhancedRole,
    RecursivePartial,
    enhanceEntity,
    filterRoleByCompany,
    form2Entity
} from '@reportroyal/api';
import { client, getNewId } from './api';
import { loadRoles } from './user';

export async function loadCompanies(
    config: CompaniesGetRequest = {},
    options?: EnhancedEntityOptions
): Promise<EnhancedCompany[]> {
    const companies = await client.companiesGet(config);

    return companies.map((item) => enhanceEntity(item, options));
}

export async function loadCompany(id: string, options?: EnhancedEntityOptions) {
    const company = await client.companyGet({
        id
    });

    return enhanceEntity<EnhancedCompany>(company, options);
}

export function putCompany(form: EnhancedCompanyForm) {
    return client.companyPut({ company: form2Entity(form) });
}

export function postCompany(form: EnhancedCompanyForm) {
    return client.companyPost({ company: form2Entity(form) });
}

export async function loadCompanyRoles(): Promise<EnhancedRole[]> {
    const roles = await loadRoles();

    return roles.filter(filterRoleByCompany);
}

export function isCompanyRole(role: EnhancedRole) {
    return (
        role.name !== 'guest' &&
        role.name !== 'user' &&
        role.name !== 'worker' &&
        role.name !== 'admin'
    );
}

export function emptyCompany(
    obj: RecursivePartial<CompanyJSON>
): EnhancedCompany {
    return enhanceEntity(
        {
            id: getNewId('Company'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            ...obj
        },
        { new: true, initForm: true }
    );
}

export function emptyCompanySub(
    obj: RecursivePartial<CompanySubJSON>
): EnhancedCompanySub {
    return enhanceEntity(
        {
            id: getNewId('CompanySub'),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            ...obj
        },
        { new: true, initForm: true }
    );
}

export async function loadCompanySubs(
    options?: EnhancedEntityOptions
): Promise<EnhancedCompanySub[]> {
    const subs = await client.companySubsGet();

    return subs.map((item) => enhanceEntity(item, options));
}

export async function loadCompanySubByCompany(
    companyId: string,
    options?: EnhancedEntityOptions
) {
    const company = await client.companySubGet({
        company: companyId
    });

    return enhanceEntity<EnhancedCompanySub>(company, options);
}

export function postCompanySub(form: EnhancedCompanySubForm) {
    return client.companySubPost({ companySub: form2Entity(form) });
}

export function putCompanySub(form: EnhancedCompanySubForm) {
    return client.companySubPut({ companySub: form2Entity(form) });
}

export function deleteCompanySub(id: string) {
    return client.companySubDelete({ id });
}
