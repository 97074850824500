import {
    AWS_CLIENT_ID,
    AWS_IDENTITY_POOL_ID,
    AWS_REGION,
    AWS_USER_POOL_ID,
    User
} from '@reportroyal/api';
import { createS3 } from '@reportroyal/s3';
import type {
    CognitoRefreshToken,
    CognitoUser
} from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { observable } from 'mobx';
import { bugsnagClient } from '../core/bugsnag';
import { client } from './api';
import { loadMyUser } from './user';
import {
    Subscription,
    closeWebSocket,
    createWebSocket,
    listenToWebsocketUpdate,
    subscribeToWebsockets
} from './websocket';

type Credentials = {
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken: string;
    expiration: Date;
    identityId: string;
    authenticated: boolean;
};

Amplify.configure({
    aws_cognito_region: AWS_REGION,
    aws_user_pools_id: AWS_USER_POOL_ID,
    aws_user_pools_web_client_id: AWS_CLIENT_ID,
    aws_cognito_identity_pool_id: AWS_IDENTITY_POOL_ID,
    aws_mandatory_sign_in: 'enable'
});

function getMeCached() {
    const meRaw = localStorage.getItem('me');
    const me = meRaw ? (JSON.parse(meRaw) as User) : null;

    if (me?.createdAt) {
        me.createdAt = new Date(me.createdAt);
    }

    if (me?.updatedAt) {
        me.updatedAt = new Date(me.updatedAt);
    }

    return me;
}

export const storage = observable.box<{
    me: User | null;
    apiKey: string;
}>({
    me: getMeCached(),
    apiKey: ''
});

const websocketSubs = {
    _subs: [] as Subscription[],
    add(...subs: Subscription[]) {
        this.unsubscribe();
        this._subs.push(...subs);
    },
    unsubscribe() {
        this._subs.forEach((dispose) => dispose());
        this._subs.length = 0;
    }
};

const autoReconnect = {
    _interval: undefined as any,

    init() {
        this.stop();

        this._interval = setInterval(connect, 1000 * 60 * 29);
    },
    stop() {
        clearInterval(this._interval);
    }
};

export function getUserCache() {
    const { me } = storage.get();

    if (me) {
        const { id, updatedAt } = me;
        const updatedAtStr =
            typeof updatedAt === 'string'
                ? 'updatedAt'
                : updatedAt.toISOString();

        return { c: btoa(`${id} ${updatedAtStr}`) };
    }

    return { c: undefined };
}

function setApiKey(user: CognitoUser) {
    storage.set({
        ...storage.get(),
        apiKey: user?.getSignInUserSession()?.getIdToken().getJwtToken() ?? ''
    });
}

export async function getCredentials(): Promise<Credentials> {
    return Auth.Credentials.get();
}

async function pause() {
    return new Promise((resolve) => {
        setTimeout(resolve, 1000);
    });
}

async function refreshSession(
    user: CognitoUser,
    refreshToken: CognitoRefreshToken
) {
    return new Promise<void>((resolve, reject) => {
        user.refreshSession(refreshToken, (err) => {
            if (err) {
                reject(new Error(err.message));
            } else {
                resolve();
            }
        });
    });
}

async function refreshToken(retries = 0) {
    const user = await getLoggedInUser();

    if (!user) {
        return null;
    }

    const session = user.getSignInUserSession();

    if (!session?.isValid()) {
        throw new Error('Session is invalid');
    }

    try {
        await refreshSession(user, session.getRefreshToken());

        return user;
    } catch (e) {
        if (retries < 3) {
            await pause();

            return refreshToken(++retries);
        } else {
            throw e;
        }
    }
}

async function authenticateUser(
    Username: string,
    Password: string
): Promise<CognitoUser> {
    const cognitoUser = (await Auth.signIn(Username, Password)) as CognitoUser;

    setApiKey(cognitoUser);

    return cognitoUser;
}

export async function getLoggedInUser(): Promise<CognitoUser | undefined> {
    try {
        return await Auth.currentAuthenticatedUser();
    } catch (e) {
        return undefined;
    }
}

export async function login(Username: string, Password: string) {
    await authenticateUser(Username, Password);
    await connect();
}

export async function passwordForgot(email: string) {
    return await client.userPasswordForgotPost({ email });
}

export async function connect() {
    try {
        const user = await refreshToken();

        if (!user) {
            return;
        }

        setApiKey(user);

        await loadMe();

        createS3(await getCredentials());
        createWebSocket();

        websocketSubs.add(
            subscribeToWebsockets(),
            listenToWebsocketUpdate({
                path: '/user',
                callback: async () => {
                    const me = await loadMyUser();

                    storage.set({ ...storage.get(), me });

                    return me;
                }
            })
        );

        autoReconnect.init();
    } catch (e) {
        const error = e instanceof Error ? e : JSON.stringify(e);

        console.error(error);
        bugsnagClient?.notify(error);
    }
}

export async function validateConnection() {
    if (!(await currentSessionIsValid())) {
        await connect();
    }
}

async function currentSessionIsValid() {
    try {
        const session = await Auth.currentSession();

        return session.isValid();
    } catch (error) {
        return false;
    }
}

export function logout() {
    return Auth.signOut();
}

export function getCleanId(entity: any) {
    return entity.key;
}

async function loadMe() {
    const me = await loadMyUser();

    storage.set({ ...storage.get(), me });
}

Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
        case 'signIn':
            setApiKey(data as CognitoUser);
            break;
        case 'signOut':
            closeWebSocket();
            autoReconnect.stop();
            websocketSubs.unsubscribe();
            storage.set({ apiKey: '', me: null });
            localStorage.removeItem('me');
            sessionStorage.clear();
            break;
        case 'customOAuthState':
            break;
    }
});
