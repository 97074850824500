import { FormState, FieldState } from 'formstate';
import { computed, makeObservable, observable } from 'mobx';
import {
    EnhancedEntity,
    EnhancedEntityOptions,
} from '../../entities/enhanced-entity';

export type EnhancedCompanySubCheckoutSessionForm = FormState<{
    clientSecret: FieldState<string>;
    status: FieldState<string | undefined>;
}>;

export class EnhancedCompanySubCheckoutSession extends EnhancedEntity<EnhancedCompanySubCheckoutSessionForm> {
    public clientSecret!: string;
    public status!: string | undefined;

    constructor(props: any, options?: EnhancedEntityOptions) {
        super();

        makeObservable(this, {
            props: computed,
            clientSecret: observable,
            status: observable,
        });

        this.init(props, options);
    }

    public get props() {
        return { clientSecret: this.clientSecret, status: this.status };
    }

    public initForm() {
        this.form = new FormState({
            clientSecret: new FieldState<string>(this.clientSecret),
            status: new FieldState<string | undefined>(this.status),
        });

        return this;
    }

    public diposeForm() {
        this.form = undefined;
    }
}
