import {
    EnhancedProject,
    EnhancedProjectType,
    Viewmode
} from '@reportroyal/api';
import { makeAutoObservable } from 'mobx';
import { component, initialize } from 'tsdi';
import { wrapRequest } from 'wrap-request';
import { loadMyRoles, loadUserProjectTypes, storage } from '../aws';

@component
export class AccessStore {
    public myRoles = wrapRequest(loadMyRoles, {
        defaultData: [],
        cacheKey: 'my-roles'
    });

    public userProjectTypes = wrapRequest(
        (userId: string) => loadUserProjectTypes({ userId }),
        { defaultData: [], cacheKey: 'user-project-types' }
    );

    public get isGuest(): boolean {
        return this.myRoles.$.includes('guest');
    }

    public get isWorker(): boolean {
        return this.isAdmin || this.myRoles.$.includes('worker');
    }

    public get isAdmin(): boolean {
        return this.myRoles.$.includes('admin');
    }

    public get isSub(): boolean {
        return Boolean(storage.get().me?.company?.subscription);
    }

    public canDownload = (viewmode?: Viewmode) => {
        return viewmode === 'live' || viewmode === 'dashboard';
    };

    public canAddClipping = (viewmode?: Viewmode) => {
        return viewmode === 'wip' && !this.myRoles.$.includes('guest');
    };

    public getProjectTypes(project: EnhancedProject): EnhancedProjectType[] {
        const projectTypes = project.types
            .filter((type) => type.active)
            .filter((type) => {
                const userProjectTypes = this.userProjectTypes.$.find(
                    (item) => item.project.id === project.id
                );

                if (userProjectTypes) {
                    return userProjectTypes.types.some(
                        (userProjectType) => userProjectType.id === type.id
                    );
                }

                return false;
            });

        if (projectTypes.length === 0 || this.isAdmin) {
            return project.types.filter((type) => type.active);
        }

        return projectTypes;
    }

    @initialize
    protected init() {
        makeAutoObservable(this);
    }

    public reset() {
        this.myRoles.reset([]);
        this.userProjectTypes.reset([]);
    }
}
