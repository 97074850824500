import { observer } from 'mobx-react';
import * as React from 'react';
import { navigate } from 'takeme';
import { AppStore } from '../app/store';
import { TabItem, Tabs } from '../components';
import { injectTSDI } from '../core/utils';
import { AccessStore } from '../core/access-store';

export const SettingsNav = observer(() => {
    const { route } = injectTSDI(AppStore);
    const { isSub } = injectTSDI(AccessStore);

    return (
        <Tabs onChange={navigate}>
            {!isSub && (
                <TabItem
                    selected={route === '/settings/leadstories'}
                    value="/settings/leadstories"
                >
                    Leadstories
                </TabItem>
            )}
            {!isSub && (
                <TabItem
                    selected={route === '/settings/authors'}
                    value="/settings/authors"
                >
                    Authors
                </TabItem>
            )}
            {!isSub && (
                <TabItem
                    selected={route === '/settings/tags'}
                    value="/settings/tags"
                >
                    Tags
                </TabItem>
            )}
            {!isSub && (
                <TabItem
                    selected={
                        route === '/settings/media/:type' ||
                        route === '/settings/media/:type/:id'
                    }
                    value="/settings/media/print"
                >
                    Media
                </TabItem>
            )}
            {!isSub && (
                <TabItem
                    selected={route === '/settings/mails'}
                    value="/settings/mails"
                >
                    E-Mails
                </TabItem>
            )}
            <TabItem
                selected={
                    route === '/settings/projects' ||
                    route === '/settings/projects/:id'
                }
                value="/settings/projects"
            >
                Projects
            </TabItem>
            <TabItem
                selected={
                    route === '/settings/users' ||
                    route === '/settings/users/:id'
                }
                value="/settings/users"
            >
                Users
            </TabItem>
            {!isSub && (
                <TabItem
                    selected={
                        route === '/settings/companies' ||
                        route === '/settings/companies/:id'
                    }
                    value="/settings/companies"
                >
                    Companies
                </TabItem>
            )}
            {!isSub && (
                <TabItem
                    selected={
                        route === '/settings/roles' ||
                        route === '/settings/roles/:id'
                    }
                    value="/settings/roles"
                >
                    Roles
                </TabItem>
            )}
        </Tabs>
    );
});
