import { EnhancedMediaPrint, EnhancedFile } from '@reportroyal/api';

function getDateFromPmgDate(pmgDate: string): Date {
    if (/\d{2}\.\d{2}\.\d{4}/.test(pmgDate)) {
        const [day, month, year] = pmgDate.split('.');

        return new Date(`${year}-${month}-${day}Z`);
    }

    if (/\d{4}\d{2}\d{2}/.test(pmgDate)) {
        const year = pmgDate.slice(0, 4);
        const month = pmgDate.slice(4, 6);
        const day = pmgDate.slice(6, 8);

        return new Date(`${year}-${month}-${day}Z`);
    }

    return new Date();
}

export async function getPropsFromPmgTitle(
    files: EnhancedFile[],
    allMedia: EnhancedMediaPrint[]
) {
    const PMG_MASKs = [
        /([A-Z]+)-(\d{2}\.\d{2}.\d{4})-/,
        /([A-Z]+)-\d{8}-(\d{2}\.\d{2}.\d{4})-/,
        /([A-Z]+)-\d{8}-(\d{4}\d{2}\d{2})-/
    ];
    const result: {
        media: EnhancedMediaPrint | undefined;
        date: Date | undefined;
    } = {
        media: undefined,
        date: undefined
    };

    await Promise.all(
        files.map(async (image) => {
            await image.loadMetadata();

            const { title } = image.headers;

            if (title) {
                const match = PMG_MASKs.reduce(
                    (memo, PMG_MASK) => {
                        if (memo) {
                            return memo;
                        }

                        return title.match(PMG_MASK);
                    },
                    null as RegExpMatchArray | null
                );

                if (match) {
                    const [, shortcut, pmgDate] = match;

                    const media = allMedia.find((item) =>
                        (item.pmgShortcut || []).some((s) => s === shortcut)
                    );
                    const date = getDateFromPmgDate(pmgDate);

                    if (media) {
                        result.media = media;
                    }

                    if (date) {
                        result.date = date;
                    }
                }
            }
        })
    );

    return result;
}
